import(/* webpackMode: "eager" */ "/builds/brand/svc/ishop/ishop-frontend/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["ScriptGA","ScriptVK","ScriptYaMetrika"] */ "/builds/brand/svc/ishop/ishop-frontend/src/_shared/scripts/metrics.script.tsx");
;
import(/* webpackMode: "eager" */ "/builds/brand/svc/ishop/ishop-frontend/src/components/layout/footer/assets/tg.icon.svg");
;
import(/* webpackMode: "eager" */ "/builds/brand/svc/ishop/ishop-frontend/src/components/layout/footer/assets/vk.icon.svg");
;
import(/* webpackMode: "eager" */ "/builds/brand/svc/ishop/ishop-frontend/src/components/layout/footer/footer.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/builds/brand/svc/ishop/ishop-frontend/src/components/layout/header/header.component.tsx");
;
import(/* webpackMode: "eager" */ "/builds/brand/svc/ishop/ishop-frontend/src/components/layout/layout.module.scss");
;
import(/* webpackMode: "eager" */ "/builds/brand/svc/ishop/ishop-frontend/src/components/simple-image/index.ts");
;
import(/* webpackMode: "eager", webpackExports: ["NavigationProvider","useNavigationContext"] */ "/builds/brand/svc/ishop/ishop-frontend/src/modules/navigation/providers/navigation.provider.tsx");
