'use client';
import { createContext, ReactNode, useContext } from 'react';
import { NavigationResponse } from '../api';

const NavigationContext = createContext<NavigationResponse | undefined>(
  {} as NavigationResponse,
);

type TNavigationProviderProps = {
  children: ReactNode;
  value: NavigationResponse | undefined;
};

export const NavigationProvider = ({
  children,
  value,
}: TNavigationProviderProps): JSX.Element => {
  return (
    <NavigationContext.Provider value={value}>
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigationContext = (): NavigationResponse | undefined =>
  useContext(NavigationContext);
